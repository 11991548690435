import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  breakpoint: {
    mobileBreakpoint: 'lg',
    thresholds: {
      xs: 375, // iPhone SE gen2 normal
      sm: 810, // iPad
      md: 1080, // iPad landscape
      lg: 1200, // Pivot screen
    },
    scrollBarWidth: 17,
  },
}

export default new Vuetify(opts)
