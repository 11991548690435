<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Obnovení hesla</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="my-1">Pro potvrzení zadej ješte jednou svoji emailovou adresu a zvol si nové heslo.</p>
            <v-text-field
              name="email"
              label="Email"
              id="email"
              v-model="credentials.email"
              @keyup.enter="submitForm"
              @input="$v.credentials.email.$touch()"
              :error-messages="emailErrors"
            ></v-text-field>
            <v-text-field
              name="password"
              label="Password"
              type="password"
              id="password"
              v-model="credentials.password"
              @keyup.enter="submitForm"
              @input="$v.credentials.password.$touch()"
              :error-messages="passwordErrors"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm" :disabled="sending">Změnit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      credentials: {
        email: null,
        password: null,
        token: null,
      },
      sending: false,
      loading: false,
    }
  },
  validations: {
    credentials: {
      email: { required, email },
      password: { required, minLength: minLength(6) },
    },
  },
  methods: {
    fetchData() {
      this.credentials.token = this.$route.params.reset
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .post('v1/public/reset-password', this.credentials)
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Heslo bylo úspěšně změněno. Můžeš se přihlásit.')
          this.$router.push({ name: 'Login' })
        })
        .catch((error) => {
          this.sending = false
          this.$v.$reset()
          if (error.response.status === 422) {
            this.$store.dispatch('showWarningMessage', 'Zadané hodnoty nejsou platné.')
          }
        })
    },
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.credentials.email.$dirty) return errors
      !this.$v.credentials.email.required && errors.push('Email je povinný')
      !this.$v.credentials.email.email && errors.push('Formát emailu není platný')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.credentials.password.$dirty) return errors
      !this.$v.credentials.password.required && errors.push('Heslo je povinné')
      !this.$v.credentials.password.minLength && errors.push('Heslo musí mít minimálně 6 znaků')
      return errors
    },
    title() {
      return window.homepageTitle ? window.homepageTitle : 'Ogymo'
    },
    logoFile() {
      return window.logoFile ? window.logoFile : 'logo.png'
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
}
</script>
