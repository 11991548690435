<template>
  <div>
    <component :is="layout" v-on:snack="snack"></component>
    <v-snackbar :timeout="6000" v-model="snackbar" :color="snackbarColor">
      {{ snackbarMessage }}
      <v-btn dark text @click.native="snackbar = false">Zavřít</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import GuestLayout from './layouts/GuestLayout'
import AdminLayout from './layouts/AdminLayout'

export default {
  components: { AdminLayout, GuestLayout },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'info',
    }
  },
  methods: {
    snack(payload) {
      this.snackbarMessage = payload.message
      this.snackbarColor = payload.color
      this.snackbar = true
    },
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'admin'}-layout`
    },
  },
}
</script>
