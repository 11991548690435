<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="my-2">
          <v-card-title>Změna adresy</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              name="street"
              label="Ulice"
              id="street"
              v-model="street"
              type="text"
              @input="$v.street.$touch()"
              @keyup.enter="submitForm"
              :error-messages="streetErrors"
            ></v-text-field>

            <v-text-field
              name="city"
              label="Město"
              id="city"
              v-model="city"
              type="text"
              @input="$v.city.$touch()"
              @keyup.enter="submitForm"
              :error-messages="cityErrors"
            ></v-text-field>

            <v-text-field
              name="zip"
              label="PSČ"
              id="zip"
              v-model="zip"
              type="text"
              @input="$v.zip.$touch()"
              @keyup.enter="submitForm"
              :error-messages="zipErrors"
            ></v-text-field>

            <v-text-field
              name="country"
              label="Kraj"
              id="country"
              v-model="country"
              type="text"
              @input="$v.country.$touch()"
              @keyup.enter="submitForm"
              :error-messages="countryErrors"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="sending" @click="submitForm">Změnit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loading: false,
      street: '',
      city: '',
      zip: '',
      country: '',
      sending: false,
    }
  },
  validations: {
    street: { required },
    city: { required },
    zip: { required },
    country: { required },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true

      const data = {
        contact_street: this.street,
        contact_city: this.city,
        contact_zip: this.zip,
        contact_country: this.country,
      }

      this.$http
        .post(`v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}/updateAddress`, data)
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Adresa byla úspěšně změněna.')
          this.$router.push({ name: 'Profile' })
        })
        .catch((error) => {
          this.$v.$reset()
          this.sending = false
          if (error.response.status === 400) {
            this.$store.dispatch('showWarningMessage', error.response.data.message)
          } else {
            this.$store.dispatch('showWarningMessage', 'Chyba při odesílání formuláře. Zkuste to prosím později.')
          }
        })
    },
    async fetchData() {
      const request = await this.$http.get(`v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}`)
      const data = request.data
      this.street = data.contact_street
      this.city = data.contact_city
      this.zip = data.contact_zip
      this.country = data.contact_country
    },
  },
  computed: {
    streetErrors() {
      const errors = []
      if (!this.$v.street.$dirty) return errors
      !this.$v.street.required && errors.push('Ulice je povinná')
      return errors
    },
    cityErrors() {
      const errors = []
      if (!this.$v.city.$dirty) return errors
      !this.$v.city.required && errors.push('Město je povinné')
      return errors
    },
    zipErrors() {
      const errors = []
      if (!this.$v.zip.$dirty) return errors
      !this.$v.zip.required && errors.push('PSČ je povinné')
      return errors
    },
    countryErrors() {
      const errors = []
      if (!this.$v.country.$dirty) return errors
      !this.$v.country.required && errors.push('Země je povinná')
      return errors
    },
  },
}
</script>
