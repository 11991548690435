<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading && !$store.getters.authenticated">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Registrace na seminář {{ event.name }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div v-html="event.description"></div>
            <v-text-field
              name="firstname"
              label="Jméno"
              id="firstname"
              v-model="registration.firstname"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.firstname.$touch()"
              :error-messages="firstnameErrors"
            ></v-text-field>

            <v-text-field
              name="lastname"
              label="Příjmení"
              id="lastname"
              v-model="registration.lastname"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.lastname.$touch()"
              :error-messages="lastnameErrors"
            ></v-text-field>

            <v-text-field
              name="email"
              label="Email"
              id="email"
              v-model="registration.email"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.email.$touch()"
              :error-messages="emailErrors"
            ></v-text-field>

            <v-text-field
              name="phone"
              label="Telefon"
              id="phone"
              v-model="registration.phone"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.phone.$touch()"
              :error-messages="phoneErrors"
            ></v-text-field>

            <v-text-field
              name="questions"
              label="Dotazy"
              id="questions"
              v-model="registration.questions"
              multi-line
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="formSubmit" :loading="sending">Zaregistrovat</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row wrap v-if="!loading && $store.getters.authenticated">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Registrace na seminář {{ event.name }}</v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ user.user.email }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="authFormSubmit" :loading="sending">Zaregistrovat</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import phone from '../validators/Phone.js'
import czech from '../validators/Czech.js'

export default {
  data() {
    return {
      user: null,
      event: {},
      registration: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
      },
      sending: false,
      loading: true,
    }
  },
  validations: {
    registration: {
      firstname: { required, czech },
      lastname: { required, czech },
      email: { required, email },
      phone: { required, phone },
    },
  },
  methods: {
    authFormSubmit() {
      this.sending = true
      this.$http
        .post(`v1/tenant/${this.$store.state.tenant}/events/${this.$route.params.id}/users`, {
          user_id: this.user.user_id,
        })
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Registrace na akci proběhla úspěšně')
          window.location = `/#/`
        })
        .catch(() => {
          this.sending = false
          this.$store.dispatch('showWarningMessage', 'Chyba! Zaregistrujte se pomocí telefonu nebo emailu.')
        })
    },
    formSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .post(`v1/public/events/${this.$route.params.id}/register`, this.registration)
        .then((response) => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', response.data.title + ' - ' + response.data.message)
          window.location = `/#/${this.slug}/login`
        })
        .catch(() => {
          this.sending = false
          this.$store.dispatch('showWarningMessage', 'Chyba! Zaregistrujte se pomocí telefonu nebo emailu.')
          this.$v.$reset()
        })
    },
    fetchData() {
      if (!this.$store.getters.isAuthenticated) {
        this.$http
          .get(`v1/public/events/${this.$route.params.id}`)
          .then((response) => {
            this.event = response.data
            this.loading = false
          })
          .catch(() => {
            this.$store.dispatch('showWarningMessage', 'Chyba při načítání dat, zkuste to prosím později.')
            window.location = '/#/' + this.slug + '/login'
          })
      } else {
        Promise.all([
          this.$http.get(`v1/public/events/${this.$route.params.id}`),
          this.$http.get(`v1/tenant-slug/${this.slug}/me`),
        ])
          .then((response) => {
            this.event = response[0].data
            this.user = response[1].data
            this.loading = false
          })
          .catch(() => {
            this.$store.dispatch('showWarningMessage', 'Chyba při načítání dat, zkuste to prosím později.')
            window.location = `/#/${this.slug}/profile`
          })
      }
    },
  },
  computed: {
    firstnameErrors() {
      const errors = []
      if (!this.$v.registration.firstname.$dirty) return errors
      !this.$v.registration.firstname.required && errors.push('Jméno je povinný')
      !this.$v.registration.firstname.czech && errors.push('Jméno nesmí obsahovat čísla nebo speciální znaky')
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.registration.lastname.$dirty) return errors
      !this.$v.registration.lastname.required && errors.push('Příjmení je povinný')
      !this.$v.registration.lastname.czech && errors.push('Příjmení nesmí obsahovat čísla nebo speciální znaky')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.registration.email.$dirty) return errors
      !this.$v.registration.email.required && errors.push('Email je povinný')
      !this.$v.registration.email.email && errors.push('Formát emailu není platný')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.registration.phone.$dirty) return errors
      !this.$v.registration.phone.required && errors.push('Telefon je povinný')
      !this.$v.registration.phone.phone && errors.push('Formát telefonu není platný')
      return errors
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
}
</script>
