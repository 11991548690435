export default {
  methods: {
    errorHandler(error) {
      if (error.response.status === 401) {
        this.$router.push('/logout')
      } else {
        if (error.response.data && error.response.data.message) {
          this.$store.dispatch('showWarningMessage', `${error.response.data.message}`)
        } else {
          this.$store.dispatch('showWarningMessage', `Something went wrong. Error: ${error.response.status}`)
        }
      }
    },
  },
}
