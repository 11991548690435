<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="my-2">
          <v-card-title>Změna hesla</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              name="oldPassword"
              label="Současné heslo"
              id="oldPassword"
              v-model="credentials.oldPassword"
              type="password"
              @input="$v.credentials.oldPassword.$touch()"
              @keyup.enter="submitForm"
              :error-messages="oldPasswordErrors"
            ></v-text-field>

            <v-text-field
              name="newPassword"
              label="Nové heslo"
              id="newPassword"
              v-model="credentials.newPassword"
              type="password"
              @input="$v.credentials.newPassword.$touch()"
              @keyup.enter="submitForm"
              :error-messages="newPasswordErrors"
            ></v-text-field>

            <v-text-field
              name="newPasswordConfirmation"
              label="Potvrzení hesla"
              id="newPasswordConfirmation"
              v-model="credentials.newPasswordConfirmation"
              type="password"
              @input="$v.credentials.newPasswordConfirmation.$touch()"
              @keyup.enter="submitForm"
              :error-messages="newPasswordConfirmationErrors"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="sending" @click="submitForm">Změnit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loading: false,
      credentials: {
        oldPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
      },
      sending: false,
    }
  },
  validations: {
    credentials: {
      oldPassword: { required },
      newPassword: { required, minLength: minLength(6) },
      newPasswordConfirmation: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('newPassword'),
      },
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .post(`v1/tenant/${this.$store.state.tenant}/change-password`, this.credentials)
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Heslo bylo úspěšně změněno.')
          this.$router.push({ name: 'Profile' })
        })
        .catch((error) => {
          this.$v.$reset()
          this.sending = false
          if (error.response.status === 400) {
            this.$store.dispatch('showWarningMessage', error.response.data.message)
          } else {
            this.$store.dispatch('showWarningMessage', 'Chyba při odesílání formuláře. Zkuste to prosím později.')
          }
        })
    },
    fetchData() {},
  },
  computed: {
    oldPasswordErrors() {
      const errors = []
      if (!this.$v.credentials.oldPassword.$dirty) return errors
      !this.$v.credentials.oldPassword.required && errors.push('Současné heslo je povinné')
      return errors
    },
    newPasswordErrors() {
      const errors = []
      if (!this.$v.credentials.newPassword.$dirty) return errors
      !this.$v.credentials.newPassword.required && errors.push('Nové heslo je povinné')
      !this.$v.credentials.newPassword.minLength && errors.push('Nové heslo musí mít alespoň 6 znaků')
      return errors
    },
    newPasswordConfirmationErrors() {
      const errors = []
      if (!this.$v.credentials.newPasswordConfirmation.$dirty) return errors
      !this.$v.credentials.newPasswordConfirmation.required && errors.push('Potvrzení hesla je povinné')
      !this.$v.credentials.newPasswordConfirmation.minLength && errors.push('Potvrezní hesla musí mít alespoň 6 znaků')
      !this.$v.credentials.newPasswordConfirmation.sameAs && errors.push('Potvrzení hesla se neshoduje s novým heslem')
      return errors
    },
  },
}
</script>
