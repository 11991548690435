import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Authentication/Login.vue'
import Logout from '../views/Authentication/Logout.vue'
import Dashboard from '../views/Profile/Dashboard.vue'
import Registration from '../views/Registration.vue'
import ForgotPassword from '../views/Authentication/ForgotPassword.vue'
import ResetPassword from '../views/Authentication/ResetPassword.vue'
import ChangePassword from '../views/Profile/ChangePassword.vue'
import EventRegistration from '../views/EventRegistration.vue'
import NewSubstitutions from '../views/Substitutions.vue'
import WaitingList from '../views/WaitingList.vue'
import CollectLeavingReason from '../views/CollectLeavingReason.vue'
import Text from '../views/Text.vue'
import Memberships from '../views/Memberships.vue'
import Excused from '../views/Excused.vue'
import Address from '../views/Profile/Address.vue'

Vue.use(VueRouter)

const routes = [
  { name: 'Profile', path: '/', component: Dashboard },
  {
    name: 'Login',
    path: '/login/:gym?',
    component: Login,
    meta: {
      layout: 'guest',
      guestAuth: 'yes',
    },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: Logout,
    meta: {
      layout: 'guest',
      guestAuth: 'yes',
    },
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'guest',
      guestAuth: 'yes',
    },
  },
  {
    name: 'ResetPassword',
    path: '/reset-password/:reset',
    component: ResetPassword,
    meta: {
      layout: 'guest',
      guestAuth: 'yes',
    },
  },
  {
    name: 'ChangePassword',
    path: '/change-password',
    component: ChangePassword,
  },
  {
    name: 'Address',
    path: '/address',
    component: Address,
  },
  { name: 'Text', path: '/texts/:slug', component: Text },
  {
    name: 'Substitutions',
    path: '/substitutions',
    component: NewSubstitutions,
  },
  {
    name: 'Excused',
    path: '/excused',
    component: Excused,
  },
  {
    name: 'Memberships',
    path: '/memberships',
    component: Memberships,
  },
  {
    name: 'WaitingList',
    path: '/:tenant?/waiting-list/:token',
    component: WaitingList,
  },
  {
    name: 'CollectLeavingReason',
    path: '/:tenant?/gyms/:gym/users/:user/reason/:reason/token/:token?',
    component: CollectLeavingReason,
  },
  {
    name: 'Registration',
    path: '/:tenant?/registration',
    component: Registration,
  },
  {
    name: 'EventRegistration',
    path: '/:tenant?/events/:id/registration',
    component: EventRegistration,
  },
]

const router = new VueRouter({
  routes,
})

export default router
