import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import Vuelidate from 'vuelidate'
import Rollbar from 'rollbar'
import vuetify from '@/plugins/vuetify'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.state.token}`
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)
Vue.prototype.$http = axios

Vue.prototype.$rollbar = new Rollbar({
  accessToken: 'a645d3a0bd0e4daea9c0d91ce7901d20',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
})

Vue.config.errorHandler = (err, vm) => {
  if (process.env.NODE_ENV != 'development') {
    vm.$rollbar.error(err)
  }
  throw err
}

Vue.use(Vuelidate)

Vue.filter('formatDate', function (value) {
  return moment(value).format('DD. MM. YYYY')
})

Vue.filter('formatDateTime', function (value) {
  return moment(value).format('DD. MM. YYYY HH:mm')
})

Vue.filter('formatTime', function (value) {
  return moment(value).format('HH:mm')
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.guestAuth) {
    next()
  } else {
    if (!store.getters.isAuthenticated) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
