<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mt-2">
          <v-card-title>Všechna členství</v-card-title>
          <v-divider></v-divider>
          <v-list three-line>
            <v-list-item v-for="item in memberships" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  <strong
                    >{{ item.dateStart | formatDate }} -
                    {{ item.dateEnd | formatDate }}
                    <span class="red--text" v-if="item.status === 'Suspended'"> ({{ item.remainingDays }} days) </span>
                  </strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.type ? item.type.name : 'No type' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span :class="statusCssClasses(item)">
                    {{ item.status }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorHandlers from '../mixins/ErrorHandlers.js'

export default {
  mixins: [ErrorHandlers],
  data() {
    return {
      memberships: [],
      loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    statusCssClasses(membership) {
      if (membership.status === 'Future') return 'blue pa-2 white--text'
      if (membership.status === 'Active') return 'green pa-2 white--text'
      if (membership.status === 'Expiring') return 'orange pa-2 white--text'
      if (membership.status === 'Expired') return 'red pa-2 white--text'
      if (membership.status === 'Suspended') return 'yellow pa-2'
    },
    async fetchData() {
      this.memberships = (
        await this.$http.get(`v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}/memberships`)
      ).data
    },
  },
}
</script>
