<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Přihlašovací formulář na ukázkový trénink</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Více informací naleznete na oficiální stránkách:
              <a href="http://www.zeleznakoule.cz/kdy-a-kde-cvicime/">http://www.zeleznakoule.cz/kdy-a-kde-cvicime/</a>
            </p>
            <p>
              V případě dalších dotazů nebo nejasností, nás neváhejte kontaktovat mailem (info@zeleznakoule.cz) nebo
              telefonicky (+420 608 177 332).
            </p>

            <v-text-field
              name="firstname"
              label="Jméno"
              id="firstname"
              v-model="registration.firstname"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.firstname.$touch()"
              :error-messages="firstnameErrors"
            ></v-text-field>

            <v-text-field
              name="lastname"
              label="Příjmení"
              id="lastname"
              v-model="registration.lastname"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.lastname.$touch()"
              :error-messages="lastnameErrors"
            ></v-text-field>

            <v-text-field
              name="email"
              label="Email"
              id="email"
              v-model="registration.email"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.email.$touch()"
              :error-messages="emailErrors"
            ></v-text-field>

            <v-text-field
              name="phone"
              label="Telefon"
              id="phone"
              v-model="registration.phone"
              single-line
              @keyup.enter="submitForm"
              @input="$v.registration.phone.$touch()"
              :error-messages="phoneErrors"
            ></v-text-field>

            <p class="headline">Gym</p>
            <v-radio-group v-model="registration.gym_id" :mandatory="true">
              <v-radio label="Hardstyle Gym Praha (Holešovice)" value="6"></v-radio>
              <v-radio label="Hardstyle Gym Praha (Národní třída)" value="1"></v-radio>
            </v-radio-group>

            <p class="headline">Co Tě zajímá?</p>

            <v-checkbox
              label="Kettlebell (zaměřeno na sílu a kondici)"
              v-model="registration.kettlebell"
              light
            ></v-checkbox>
            <v-checkbox
              label="Ground Force Method (zaměřeno na pohyblivost a koordinaci)"
              v-model="registration.groundForce"
              light
            ></v-checkbox>

            <v-text-field
              name="questions"
              label="Dotazy"
              id="questions"
              v-model="registration.questions"
              multi-line
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn @click="submitForm" color="primary" :loading="sending">Zaregistrovat</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import phone from '../validators/Phone.js'
import czech from '../validators/Czech.js'

export default {
  data() {
    return {
      registration: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        kettlebell: false,
        groundForce: false,
        gym_id: 6,
      },
      sending: false,
      loading: false,
    }
  },
  validations: {
    registration: {
      firstname: { required, czech },
      lastname: { required, czech },
      email: { required, email },
      phone: { required, phone },
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .postNoAuth('v1/public/register', this.registration)
        .then((response) => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', response.data.message)
          window.location = '/#/'
        })
        .catch(() => {
          this.sending = false
          this.$v.$reset()
          this.$store.dispatch(
            'showWarningMessage',
            'Chyba při odesílání formuláře. Prosím zaregistrujte se pomocí telefonu nebo emailu.'
          )
        })
    },
  },
  computed: {
    firstnameErrors() {
      const errors = []
      if (!this.$v.registration.firstname.$dirty) return errors
      !this.$v.registration.firstname.required && errors.push('Jméno je povinný')
      !this.$v.registration.firstname.czech && errors.push('Jméno nesmí obsahovat čísla nebo speciální znaky')
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.registration.lastname.$dirty) return errors
      !this.$v.registration.lastname.required && errors.push('Příjmení je povinný')
      !this.$v.registration.lastname.czech && errors.push('Příjmení nesmí obsahovat čísla nebo speciální znaky')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.registration.email.$dirty) return errors
      !this.$v.registration.email.required && errors.push('Email je povinný')
      !this.$v.registration.email.email && errors.push('Formát emailu není platný')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.registration.phone.$dirty) return errors
      !this.$v.registration.phone.required && errors.push('Telefon je povinný')
      !this.$v.registration.phone.phone && errors.push('Formát telefonu není platný')
      return errors
    },
  },
}
</script>
