<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Přihlášení</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              name="email"
              label="Email"
              id="email"
              v-model="credentials.username"
              @keyup.enter="submitForm"
              @input="$v.credentials.username.$touch()"
              :error-messages="usernameErrors"
            ></v-text-field>
            <v-text-field
              name="password"
              label="Heslo"
              id="password"
              v-model="credentials.password"
              type="password"
              @input="$v.credentials.password.$touch()"
              @keyup.enter="submitForm"
              :error-messages="passwordErrors"
            ></v-text-field>
            <v-select
              v-if="!this.$route.params.gym"
              label="Gym"
              v-model="tenant"
              item-text="name"
              @input="$v.tenant.$touch()"
              return-object
              :items="availableTenants"
              :error-messages="tenantErrors"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn light :to="{ name: 'ForgotPassword' }">Zapomněl jsem heslo</v-btn>
            <v-btn color="primary" @click="submitForm" :loading="submitting" :disabled="submitting">Přihlásit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loading: false,
      tenants: [],
      tenant: {},
      credentials: {
        username: null,
        password: null,
      },
      submitting: false,
    }
  },
  validations: {
    credentials: {
      username: { required, email },
      password: { required },
    },
    tenant: { required },
  },
  created() {
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      this.loading = true
      if (this.$route.params.gym) {
        this.tenant = { id: 1 }
        this.loading = false
      } else {
        this.$http
          .get('v1/public/gyms')
          .then((response) => {
            this.loading = false
            this.tenants = response.data
            this.loading = false
          })
          .catch(() => {
            this.$store.dispatch('showWarningMessage', 'Nepodařilo se načíst seznam gymů.')
          })
      }
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.submitting = true
      this.$http
        .post('v1/public/login', this.credentials)
        .then((response) => {
          if (response.data.tenants && response.data.tenants.find((tenant) => tenant.id === this.tenant.id)) {
            this.$store.dispatch('setToken', response.data.access_token)
            this.$store.dispatch('setTenant', this.tenant.id)
            this.$store.dispatch('setUser', response.data.user_id)
            this.$store.dispatch('setAvailableTenants', response.data.tenants)
            this.$router.push({ name: 'Profile' })
          } else {
            this.$store.dispatch('showWarningMessage', 'Nemáte profil v tomto gymu.')
          }
        })
        .catch(() => {
          this.$v.$reset()
          this.credentials.username = null
          this.credentials.password = null
          this.$store.dispatch('showWarningMessage', 'Nesprávné přihlašovací údaje.')
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  computed: {
    usernameErrors() {
      const errors = []
      if (!this.$v.credentials.username.$dirty) return errors
      !this.$v.credentials.username.required && errors.push('Email je povinný')
      !this.$v.credentials.username.email && errors.push('Formát emailu není platný')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.credentials.password.$dirty) return errors
      !this.$v.credentials.password.required && errors.push('Heslo je povinné')
      return errors
    },
    tenantErrors() {
      const errors = []
      if (!this.$v.tenant.$dirty) return errors
      !this.$v.tenant.required && errors.push('Gym je povinný')
      return errors
    },
    availableTenants() {
      return this.tenants.filter((tenant) => {
        return tenant.is_public
      })
    },
  },
}
</script>
