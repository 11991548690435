<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mt-2">
          <v-card-title>Všechny omluvené tréninky</v-card-title>
          <v-divider></v-divider>
          <v-list two-line v-if="trainings.length > 0">
            <v-list-item v-for="training in trainings" :key="training.id">
              <v-list-item-content>
                <v-list-item-title v-html="formatTrainingHeader(training)"> </v-list-item-title>
                <v-list-item-subtitle
                  >{{ training.start | formatTime }} - {{ training.end | formatTime }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text v-if="trainings.length <= 0"> Žádné omluvené tréninky </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorHandlers from '../mixins/ErrorHandlers.js'
import moment from 'moment'

export default {
  mixins: [ErrorHandlers],
  data() {
    return {
      trainings: [],
      loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    formatTrainingHeader(training) {
      var date = moment(training.start).format('D. M.')
      var formatted = ''
      switch (moment(training.start).format('d')) {
        case '0':
          formatted = `ne ${date}`
          break
        case '1':
          formatted = `po ${date}`
          break
        case '2':
          formatted = `út ${date}`
          break
        case '3':
          formatted = `st ${date}`
          break
        case '4':
          formatted = `čt ${date}`
          break
        case '5':
          formatted = `pá ${date}`
          break
        case '6':
          formatted = `so ${date}`
          break
      }
      return formatted
    },
    async fetchData() {
      this.trainings = (
        await this.$http.get(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}/trainings/excused`
        )
      ).data
    },
  },
}
</script>
