<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Zapomněl jsem heslo</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="my-1">Pro obnovení hesla zadejte prosím svojí emailovou adresu.</p>
            <v-text-field
              name="email"
              label="Email"
              id="email"
              v-model="data.email"
              @keyup.enter="submitForm"
              @input="$v.data.email.$touch()"
              :error-messages="emailErrors"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm" :disabled="sending">Obnovit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loading: false,
      data: {
        email: null,
      },
      sending: false,
    }
  },
  validations: {
    data: {
      email: { required, email },
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .post(`v1/public/forgot-password`, this.data)
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Na Tvůj email byly zaslány instrukce pro obnovení hesla.')
          this.$router.push({ name: 'Login' })
        })
        .catch((error) => {
          this.sending = false
          this.$v.$reset()
          if (error.response.status === 422) {
            this.$store.dispatch('showWarningMessage', 'Uživatel se zadanou emailovou adresou neexistuje.')
          }
        })
    },
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.data.email.$dirty) return errors
      !this.$v.data.email.required && errors.push('Email je povinný')
      !this.$v.data.email.email && errors.push('Formát emailu není platný')
      return errors
    },
  },
}
</script>
