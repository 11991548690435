<template>
  <v-row wrap v-if="announcement">
    <v-col xs12>
      <v-card class="my-2">
        <v-card-title>Oznámení</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-html="announcement"></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      announcement: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http
        .get(`v1/tenant/${this.$store.state.tenant}/gyms/${this.$store.state.tenant}`)
        .then((response) => {
          if (response.data.public_announcement) {
            this.announcement = response.data.public_announcement
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
