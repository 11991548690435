<template>
  <v-app>
    <v-navigation-drawer app fixed clipped v-model="drawer" enable-resize-watcher>
      <v-list dense>
        <v-list-item :to="{ name: 'Profile' }">
          <v-list-item-content>
            <v-list-item-title>Můj profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Substitutions' }">
          <v-list-item-content>
            <v-list-item-title>Nahrazování tréninků</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Address' }">
          <v-list-item-content>
            <v-list-item-title>Kontaktní adresa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'ChangePassword' }">
          <v-list-item-content>
            <v-list-item-title>Změna hesla</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{
            name: 'Text',
            params: { tenant: $store.state.tenant, slug: 'substitution-rules' },
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Pravidla nahrazování tréninků</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed dark app clipped-left>
      <v-app-bar-nav-icon @click.native.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>{{ $store.getters.getTenantName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon ripple :to="{ name: 'Logout' }" v-bind="attrs" v-on="on">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <the-footer />
    <the-snackbar />
  </v-app>
</template>

<script>
import TheFooter from './TheFooter.vue'
import TheSnackbar from '../components/TheSnackbar.vue'

export default {
  components: { TheFooter, TheSnackbar },
  data() {
    return {
      drawer: true,
    }
  },
}
</script>
