<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="mt-2">
          <v-card-title>{{ text.title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-html="text.text"> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorHandlers from '../mixins/ErrorHandlers.js'

export default {
  mixins: [ErrorHandlers],
  data() {
    return {
      text: null,
      loading: true,
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      this.$http
        .get(`v1/tenant/${this.$store.state.tenant}/texts/${this.$route.params.slug}`)
        .then((response) => {
          this.text = response.data
          this.loading = false
        })
        .catch(this.errorHandler)
    },
  },
}
</script>
