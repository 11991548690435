<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Zpětná vazba</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mt-1 mb-3">Mrzí nás, že jsi u nás přestal trénovat a rádi bychom věděli, co můžeme zlepšit.</p>

            <label class="form-group__label" for="reason">Důvod</label>

            <v-select
              :items="reasons"
              v-model="feedback.reason"
              label="Klikni pro výběr..."
              single-line
              item-text="name"
              item-value="id"
              :error-messages="reasonErrors"
            ></v-select>

            <v-text-field
              name="comment"
              label="Tvůj komentář"
              id="comment"
              v-model="feedback.comment"
              @keyup.enter="submitForm"
              multi-line
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-2 py-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm" :loading="sending">Odeslat</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      reasons: [],
      feedback: {
        reason: null,
        comment: null,
        token: null,
        tenant_id: null,
      },
      sending: false,
      loading: true,
    }
  },
  validations: {
    feedback: {
      reason: { required },
    },
  },
  methods: {
    fetchData() {
      this.$http
        .get(`v1/public/leaving-reasons`)
        .then((response) => {
          this.reasons = response.data
          this.reasons.forEach((item) => {
            if (item.id === parseInt(this.$route.params.reason)) {
              this.feedback.reason = item.id
            }
          })
          this.feedback.token = this.$route.params.token
          this.feedback.tenant_id = this.$route.params.gym
          this.loading = false

          // Send a reason
          this.$http
            .post(`v1/public/users/${this.$route.params.user}/leaving-reasons`, this.feedback)
            .then(() => {
              console.log('default feedback sent')
            })
            .catch((error) => {
              this.$store.dispatch('showWarningMessage', error.response.data.message)
            })
        })
        .catch(() => {
          this.$store.dispatch('showWarningMessage', 'Něco se pokazilo.')
        })
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.sending = true
      this.$http
        .post(`v1/public/users/${this.$route.params.user}/leaving-reasons`, this.feedback)
        .then(() => {
          this.sending = false
          this.$store.dispatch('showSuccessMessage', 'Tvoje zpětná vazba byla zaznamenána.')
          location.href = '/#/'
        })
        .catch((error) => {
          this.sending = false
          this.$v.$reset()
          let message = error.response.data.message
          if (message) {
            this.$store.dispatch('showWarningMessage', error.response.data.message)
          } else {
            this.$store.dispatch('showWarningMessage', 'Chyba při odesílání dat.')
          }
        })
    },
  },
  computed: {
    logoFile() {
      return window.logoFile ? window.logoFile : 'logo.png'
    },
    reasonErrors() {
      const errors = []
      if (!this.$v.feedback.reason.$dirty) return errors
      !this.$v.feedback.reason.required && errors.push('Musíš vybrat důvod')
      return errors
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.token) {
      next()
    } else {
      next((vm) => {
        vm.$emit('snack', {
          message: 'Pro zadání zpětné vazby je vyžadováno jednorázové heslo.',
          color: 'error',
        })
      })
    }
  },
}
</script>
