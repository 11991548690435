<template>
  <div />
</template>
<script>
export default {
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('tenant')
    localStorage.removeItem('user_id')
    localStorage.removeItem('available_tenants')
    sessionStorage.clear()
    this.$store.commit('SET_TOKEN', '')
    this.$store.commit('SET_TENANT', null)
    this.$store.commit('SET_USER', null)
    this.$store.commit('SET_AVAILABLE_TENANTS', [])
    this.$router.push({ name: 'Login' })
  },
}
</script>
