import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    tenant: parseInt(localStorage.getItem('tenant')) || null,
    user_id: parseInt(localStorage.getItem('user_id')) || null,
    available_tenants: JSON.parse(localStorage.getItem('available_tenants')) || [],
    popup: false,
    popupMessage: '',
    popupColor: 'black',
  },
  mutations: {
    SET_AVAILABLE_TENANTS(state, payload) {
      state.available_tenants = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_TENANT(state, payload) {
      state.tenant = payload
    },
    SET_USER(state, payload) {
      state.user_id = payload
    },
    SHOW_POPUP(state) {
      state.popup = true
    },
    HIDE_POPUP(state) {
      state.popup = false
    },
    SET_POPUP_MESSAGE(state, payload) {
      state.popupMessage = payload
    },
    SET_POPUP_COLOR(state, payload) {
      state.popupColor = payload
    },
  },
  actions: {
    setAvailableTenants({ commit }, payload) {
      commit('SET_AVAILABLE_TENANTS', payload)
      localStorage.setItem('available_tenants', JSON.stringify(payload))
    },
    setTenant({ commit }, payload) {
      commit('SET_TENANT', payload)
      localStorage.setItem('tenant', payload)
    },
    setToken({ commit }, payload) {
      commit('SET_TOKEN', payload)
      localStorage.setItem('token', payload)
    },
    setUser({ commit }, payload) {
      commit('SET_USER', payload)
      localStorage.setItem('user_id', payload)
    },
    setPermissions({ commit }, payload) {
      commit('SET_PERMISSIONS', payload)
      localStorage.setItem('permissions', JSON.stringify(payload))
    },
    showSuccessMessage({ commit }, payload) {
      commit('SET_POPUP_MESSAGE', payload)
      commit('SET_POPUP_COLOR', 'success')
      commit('SHOW_POPUP')
    },
    showWarningMessage({ commit }, payload) {
      commit('SET_POPUP_MESSAGE', payload)
      commit('SET_POPUP_COLOR', 'warning')
      commit('SHOW_POPUP')
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token
    },
    getTenantName(state) {
      if (state.available_tenants) {
        return state.available_tenants.find((tenant) => tenant.id === state.tenant).name
      } else {
        return ''
      }
    },
  },
})
