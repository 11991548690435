<template>
  <v-container fluid>
    <v-progress-linear v-bind:indeterminate="true" v-if="loading"></v-progress-linear>
    <v-row wrap v-if="!loading">
      <v-col xs12 lg8 offset-lg2>
        <v-card class="mt-4">
          <v-card-title>Potvrzení zájmu o trénování</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p v-if="!errorMessage">
              V trénikové skupině
              <strong>{{ reserve.group.name }}</strong> se uvolnilo volné místo a evidujeme Tě jako prvního náhradníka.
              Dej nám prosím vědět zda máš stále zájem trénovat v této skupině. V případě, že ano budeš automaticky
              zařazen do trénikové skupiny a obdržíš email s intrukcemi, kdy se dostavit na první trénink. Pokud nemáš
              zájem, budeš vyřazen ze seznamu náhraníků.
            </p>
            <p v-if="errorMessage">
              {{ errorMessage }}
            </p>
          </v-card-text>
          <v-card-actions v-if="!errorMessage">
            <v-btn class="btn is-danger" @click="reject()">Ne, děkuji</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="btn is-primary" @click="confirm()">Ano, mám zájem</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      reserve: {},
      token: null,
      errorMessage: '',
      loading: false,
    }
  },
  methods: {
    confirm() {
      this.reserve.token = this.token
      this.$http
        .post(`v1/public/waitingListConfirmations`, this.reserve)
        .then(() => {
          this.errorMessage = 'Byl jsi zařazen do tréninkové skupiny. V emailu obdržíš další instrukce.'
        })
        .catch((response) => {
          if (response.status === 401) {
            this.errorMessage = 'Je nám líto, ale tvoje rezervace již expirovala.'
          } else if (response.status === 402) {
            this.errorMessage = 'Ověřovací kod je neplatný'
          } else {
            this.errorMessage = 'Došlo k chybě v systému, zkus to prosím pozdeji'
          }
        })
    },
    reject() {
      this.reserve.token = this.token
      this.$http
        .delete(`v1/public/waitingListConfirmations/${this.reserve.id}`, this.reserve)
        .then(() => {
          this.errorMessage = 'Byl si odebrán ze seznamu náhradníků.'
        })
        .catch((response) => {
          if (response.status === 401) {
            this.errorMessage = 'Je nám líto, ale tvoje rezervace již expirovala.'
          } else if (response.status === 402) {
            this.errorMessage = 'Ověřovací kod je neplatný'
          } else {
            this.errorMessage = 'Došlo k chybě v systému, zkus to prosím pozdeji'
          }
        })
    },
    fetchData() {
      this.token = this.$route.params.token
      this.$http
        .get(`v1/public/waitingListConfirmations/${this.$route.params.id}?token=${this.token}`)
        .then((response) => {
          this.reserve = response[0].data
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.errorMessage = 'Je nám líto, ale tvoje rezervace již expirovala.'
          } else if (error.response.status === 402) {
            this.errorMessage = 'Ověřovací kod je neplatný'
          } else {
            this.errorMessage = 'Došlo k chybě v systému, zkus to prosím pozdeji'
          }
        })
    },
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.token) {
      next()
    } else {
      next((vm) => {
        vm.$emit('snack', {
          message: 'Je vyžadováno jednorázové heslo zaslané na Tvůj email',
          color: 'error',
        })
        window.location.replace('/#login')
      })
    }
  },
}
</script>
