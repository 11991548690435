<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <announcement />
    <v-row wrap v-if="!loading">
      <v-col xs12>
        <v-card class="my-2">
          <v-card-title>{{ user.firstname }} {{ user.lastname }}</v-card-title>
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ user.email }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card class="my-2">
          <v-card-title>Aktivní členství</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="activeMemberships.length == 0">
            <p class="mt-3">Nemáš žádná aktivní členství</p>
          </v-card-text>
          <v-list two-line v-if="activeMemberships.length > 0">
            <v-list-item v-for="item in activeMemberships" :key="item.id">
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.dateStart | formatDate }} - {{ item.dateEnd | formatDate }}</v-list-item-title
                >
                <v-list-item-subtitle>{{ item.type ? item.type.name : 'No type' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorHandlers from '../../mixins/ErrorHandlers'
import Announcement from '../../components/Announcement.vue'

export default {
  components: { Announcement },
  mixins: [ErrorHandlers],
  data() {
    return {
      user: null,
      activeMemberships: [],
      reservedTrainigs: [],
      loading: true,
      availableTenants: [],
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      return Promise.all([
        this.$http.get(`v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}`),
        this.$http.get(
          `v1/tenant/${this.$store.state.tenant}/users/${this.$store.state.user_id}/memberships?filter=active-or-future`
        ),
      ])
        .then((response) => {
          this.user = response[0].data
          this.activeMemberships = response[1].data
          this.loading = false
        })
        .catch(this.errorHandler)
    },
  },
}
</script>
